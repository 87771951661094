<template>
  <h2 class="text-center mt-5">Talent Accelerator Individual Development Plan</h2>
  <div class="row mt-3 mb-3">
    <div class="col-1 mt-2">
      <h5>Timeline:</h5>
    </div>
    <div class="col-3">
      <select class="form-select" v-model="timeline">
        <option value="2025">2025</option>
      </select>
    </div>
  </div>
  <h4>Section 1: Information</h4>
  <table class="table">
    <tbody>
      <tr>
        <th class="header col-2">Name</th>
        <td>{{ information.name }}</td>
        <th class="header col-2">Department</th>
        <td>{{ information.department }}</td>
      </tr>
      <tr>
        <th class="header col-2">Position</th>
        <td>{{ information.position }}</td>
        <th class="header col-2">Mentor Name</th>
        <td><div v-for="mentor in information.mentorName" :key="mentor">{{ mentor }}</div></td>
      </tr>
    </tbody>
  </table>
  <h4>Section 2: KPI Targets</h4>
  <table class="table">
    <thead>
      <tr class="header">
        <th scope="col" class="text-center align-middle" rowspan="2">KPI</th>
        <th scope="col" class="text-center align-middle" rowspan="2">Measurement</th>
        <th scope="col" class="text-center align-middle" rowspan="2">Weight</th>
        <th scope="col" class="text-center" colspan="5">Rating</th>
      </tr>
      <tr class="header">
        <th scope="col" class="text-center">5</th>
        <th scope="col" class="text-center">4</th>
        <th scope="col" class="text-center">3</th>
        <th scope="col" class="text-center">2</th>
        <th scope="col" class="text-center">1</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="kpi in kpiList" :key='kpi["kpi-measurement"]'>
        <td>{{ kpi.kpi }}</td>
        <td>{{ kpi.measurement }}</td>
        <td>{{ kpi.weight * 100 + "%" }}</td>
        <td v-for="score in [5, 4, 3, 2, 1]" :key="score">{{ kpi[score] }}</td>
      </tr>
    </tbody>
  </table>
  <h4>Section 3: Achievement</h4>
  <table class="table">
    <thead>
      <tr class="header">
        <th scope="col" colspan="4">
          What are key dimensions of growth, do you aim to achieve this year (Choose at least 3, not over 5)
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="ach in achievement" :key="ach">
        <th class="header" style="width: 15%">Dimensions</th>
        <td style="width: 30%">
          <select class="form-select" v-model="ach.dimension">
            <option value="kpi">KPI metrics</option>
            <option value="feedback">Development area from previous feedback</option>
            <option value="skill">Current skill advancement</option>
            <option value="2ndSkill">2nd skill development</option>
          </select>
        </td>
        <th class="header" style="width: 15%">Achievement targets</th>
        <td style="width: 40%"><textarea class="form-control" rows="3" v-model="ach.targets"></textarea></td>
      </tr>
    </tbody>
  </table>
  <button type="button" class="btn btn-secondary mb-3" @click="addAchievement">Add a New Row</button>
  <h4>Section 4: Career Aspirations</h4>
  <table class="table mb-0">
    <tbody>
      <tr>
        <th class="header col-4">Aspirations Short-Term (1-2Y)</th>
        <td><textarea class="form-control" rows="3" v-model="career.short_term"></textarea></td>
      </tr>
      <tr>
        <th class="header col-4">Aspirations Long-Term (3-5Y)</th>
        <td><textarea class="form-control" rows="3" v-model="career.long_term"></textarea></td>
      </tr>
    </tbody>
  </table>
  <table class="table">
    <tbody>
      <tr>
        <th class="header col-6">Are you interested in career promoiton opportunities within Bluebik</th>
        <td>
          <select class="form-select" v-model="career.promotion">
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </td>
      </tr>
      <tr v-if="isInteredInPromotion">
        <th class="header col-6">What positions are you interested in</th>
        <td><input class="form-control" v-model="career.position"></td>
      </tr>
      <tr v-if="isInteredInPromotion">
        <th class="header col-6">Key development areas for growth to promoiton</th>
        <td><input class="form-control" v-model="career.development"></td>
      </tr>
      <tr v-if="isInteredInPromotion">
        <th class="header col-6">Would you be open to relocation if a suitable position became available</th>
        <td>
          <select class="form-select" v-model="career.relocation">
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </td>
      </tr>
    </tbody>
  </table>
  <h4>Section 5: Individual Development Plan</h4>
  <table class="table table-fixed">
    <thead>
      <tr class="header">
        <th scope="col" class="text-center">Area of Improvement (skills)</th>
        <th scope="col" class="text-center">Desired Outcome</th>
        <th scope="col" class="text-center">Progress Update</th>
        <th scope="col" class="text-center">Timeline</th>
        <th scope="col" class="text-center">Status</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="dev in development" :key="dev">
        <td><textarea class="form-control" rows="3" v-model="dev.area"></textarea></td>
        <td><textarea class="form-control" rows="3" v-model="dev.outcome"></textarea></td>
        <td><textarea class="form-control" rows="3" v-model="dev.progress"></textarea></td>
        <td><input class="form-control" type="date" v-model="dev.timeline"></td>
        <td>
          <select class="form-select" v-model="dev.status">
            <option value="Not Start">Not Start</option>
            <option value="In Progress">In Progress</option>
            <option value="Completed">Completed</option>
            <option value="Continue">Continue</option>
          </select>
        </td>
      </tr>
    </tbody>
  </table>
  <button type="button" class="btn btn-secondary mb-3" @click="addDevelopment">Add a New Row</button>
  <h4>Section 6: Performance Evaluaiton</h4>
  <table class="table">
    <tbody>
      <tr>
        <th class="header col-3">Performance</th>
        <td></td>
      </tr>
      <tr>
        <th class="header col-3">Period</th>
        <td></td>
      </tr>
      <tr>
        <th class="header col-3">Comment</th>
        <td></td>
      </tr>
    </tbody>
  </table>
  <h4>Section 7: Mentor Comment</h4>
  <table class="table">
    <thead>
      <tr class="header">
        <th class="text-center col-2 ">Period</th>
        <th class="text-center">Mentor's Comment</th>
        <th class="text-center col-3">Mentor's Name</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="comment, mentor in mentorComment" :key="mentor">
        <th class="header">H1/{{ timeline }}</th>
        <td><textarea class="form-control" rows="3" v-model="comment['H1/' + timeline]"></textarea></td>
        <td>{{ mentor }}</td>
      </tr>
      <tr v-for="comment, mentor in mentorComment" :key="mentor">
        <th class="header">H2/{{ timeline }}</th>
        <td><textarea class="form-control" rows="3" v-model="comment['H2/' + timeline]"></textarea></td>
        <td>{{ mentor }}</td>
      </tr>
    </tbody>
  </table>
  <div class="text-center mb-5">
    <button type="button" class="btn btn-primary" @click="save">Save</button>
  </div>
</template>

<style scoped>
.header {
  background-color: #DEEBF7;
}
</style>

<script>
import axios from 'axios';
import { BACKEND } from '@/api';
const MAX_ACHIEVEMENT = 5;

export default {
  name: 'IDPItem',
  data() {
    return {
      timeline: '2025',
      information: {},
      kpiList: [],
      achievement: [],
      career: {},
      development: [],
      mentorComment: {}
    };
  },
  computed: {
    isInteredInPromotion() {
      return this.career.promotion === "Yes";
    }
  },
  methods: {
    addAchievement() {
      if (this.achievement.length < MAX_ACHIEVEMENT) {
        this.achievement.push({});
      }
    },
    addDevelopment() {
      this.development.push({});
    },
    load() {
      this.information = {};
      this.kpiList = [];
      this.career = {
        shortTerm: '',
        longTerm: ''
      };
      this.development = [];
      axios.get(`${BACKEND}/idp?timeline=${this.timeline}`, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        if (response.status === 200) {
          this.information.name = response.data.evaluatee_name;
          this.information.department = response.data.evaluatee_bu;
          this.information.position = response.data.evaluatee_position;
          this.information.mentorName = response.data.mentor_name;

          this.career = response.data.career;

          this.achievement = response.data.achievement;
          if (this.achievement.length < MAX_ACHIEVEMENT) {
            this.achievement.push({});
          }

          this.development = response.data.development;
          this.development.push({});

          this.mentorComment = response.data.mentor_comment;
        }
      }).catch(error => {
        console.log(error);
      });
      axios.get(`${BACKEND}/kpi?timeline=Q1/${this.timeline}`, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        if (response.status === 200) {
          response.data.sort((a, b) => a.kpi.localeCompare(b.kpi));
          response.data.sort((a, b) => b.weight.localeCompare(a.weight));
          this.kpiList = response.data;
        }
      }).catch(error => {
        console.log(error);
      });
    },
    save() {
      axios.patch(`${BACKEND}/idp`, {
        timeline: this.timeline,
        achievement: this.achievement,
        career: this.career,
        development: this.development,
        mentor_comment: this.mentorComment
      }, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        if (response.status === 200) {
          alert("Data has been saved.")
        }
      }).catch(error => {
        console.log(error);
      });
    }
  },
  created() {
    this.load();
  }
}
</script>
