import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { createStore } from 'vuex';
import { LOGOUT_URL } from './api';

import App from './App.vue';
import LandingPage from './components/LandingPage.vue';
import FormItem from './components/FormItem.vue';
import FormList from './components/FormList.vue';
import FormItemEdit from './components/FormItemEdit.vue';
import KPIList from './components/KPIList.vue';
import LoginPanel from './components/LoginPanel.vue';
import CalibrationList from './components/CalibrationList.vue';
import CalibrationItem from './components/CalibrationItem.vue';
import CalibrationItemEdit from './components/CalibrationItemEdit.vue';
import AdminFormList from './components/AdminFormList.vue';
import IDPItem from './components/IDPItem.vue';


const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: LandingPage, name: 'LandingPage' },
    { path: '/login', component: LoginPanel, name: 'LoginPanel' },
    { path: '/forms', component: FormList, name: 'FormList' },
    { path: '/forms/:formId', component: FormItem, name: 'FormItem' },
    { path: '/forms/:formId/edit', component: FormItemEdit, name: 'FormItemEdit' },
    { path: '/probation', component: FormList, name: 'ProbationList' },
    { path: '/approvals', component: CalibrationItem, name: 'ApprovalItem' },
    { path: '/kpi', component: KPIList, name: 'KPIList' },
    { path: '/idp', component: IDPItem, name: 'IDPItem' },
    { path: '/calibration', component: CalibrationList, name: 'CalibrationList' },
    { path: '/calibration/:name', component: CalibrationItem, name: 'CalibrationItem' },
    { path: '/calibration/:name/edit', component: CalibrationItemEdit, name: 'CalibrationItemEdit' },
    { path: '/forms/all', component: AdminFormList, name: 'AdminFormList'},
    { path: '/forms/new', component: FormItemEdit, name: 'FormItemNew' },
  ]
});

const store = createStore({
  state() {
    return {
      email: '',
      idToken: '',
      refreshToken: '',
      groups: ''
    };
  },
  getters: {
    email(state) { return state.email; },
    idToken(state) { return state.idToken; },
    refreshToken(state) { return state.refreshToken; },
    groups(state) { return state.groups; }
  },
  mutations: {
    loginSuccess(state, payload) {
      state.email = payload.email;
      state.idToken = payload.idToken;
      state.refreshToken = payload.refreshToken;
    },
    setEmail(state, payload) {
      state.email = payload.email;
    },
    setGroups(state, payload) {
      if (typeof payload.groups !== 'undefined') {
        state.groups = payload.groups;
      }
    },
    logout(state) {
      state.email = '';
      state.idToken = '';
      state.refreshToken = '';
      state.groups = '';
      localStorage.removeItem('email');
      localStorage.removeItem('idToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('groups');
      window.location.href = LOGOUT_URL;
    }
  }
});

const app = createApp(App);

app.use(router);

app.use(store);

app.mount('#app');
