<template>
  <nav class="navbar navbar-expand-sm navbar-dark bg-dark">
    <div class="container-fluid">
      <a class="navbar-brand">Bluebik Performance</a>
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav">
          <li class="nav-item" :class="{ active: isFormList }" v-if="isLoggedIn">
            <router-link class="nav-link" to="/forms">Q-Perf</router-link>
          </li>
          <li class="nav-item" :class="{ active: isProbationList }" v-if="isLoggedIn">
            <router-link class="nav-link" to="/probation">Probation</router-link>
          </li>
          <li class="nav-item" :class="{ active: isKPIList }" v-if="isLoggedIn">
            <router-link class="nav-link" to="/kpi">KPI</router-link>
          </li>
          <li class="nav-item" :class="{ active: isIDPItem }" v-if="isLoggedIn">
            <router-link class="nav-link" to="/idp">IDP</router-link>
          </li>
          <li class="nav-item" :class="{ active: isCalibrationList }" v-if="isLoggedIn && isCalibrationGroup">
            <router-link class="nav-link" to="/calibration">Calibration</router-link>
          </li>
          <li class="nav-item" :class="{ active: isAdminFormList }" v-if="isLoggedIn && isAdminGroup">
            <router-link class="nav-link" to="/forms/all">Admin</router-link>
          </li>
        </ul>
      </div>
      <span v-if="isLoggedIn" class="text-light">
        Login: <b>{{ this.$store.getters.email }}</b> <a class="pointer" @click="logout">Log out</a>
      </span>
    </div>
  </nav>
  <div :class="{ container: !shouldBeFullWidth, 'px-3': shouldBeFullWidth }">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    isLoggedIn() {
      return this.$store.getters.email !== '';
    },
    isFormList() {
      return this.$route.name === 'FormList';
    },
    isProbationList() {
      return this.$route.name === 'ProbationList';
    },
    isKPIList() {
      return this.$route.name === 'KPIList';
    },
    isIDPItem() {
      return this.$route.name === "IDPItem";
    },
    isCalibrationList() {
      return this.$route.name === 'CalibrationList';
    },
    isAdminFormList() {
      return this.$route.name === 'AdminFormList';
    },
    isAdminKPIList() {
      return this.$route.name === 'AdminKPIList';
    },
    isCalibrationGroup() {
      return this.$store.getters.groups?.split(',').some(x => x.startsWith('Calibration_'));
    },
    isAdminGroup() {
      return this.$store.getters.groups?.split(',').some(x => x.startsWith('Admin_'));
    },
    isAdminKPIGroup() {
      return this.$store.getters.groups?.split(',').some(x => x.startsWith('KPI_'));
    },
    shouldBeFullWidth() {
      const widePageNames = ['ApprovalItem', 'CalibrationItem', 'AdminFormList']
      return widePageNames.includes(this.$route.name);
    }
  },
  methods: {
    logout() {
      this.$store.commit('logout');
    }
  },
  created() {
    if (localStorage.getItem('email') !== null) {
      this.$store.commit('loginSuccess', {
        email: localStorage.getItem('email'),
        idToken: localStorage.getItem('idToken'),
        refreshToken: localStorage.getItem('refreshToken')
      });
      this.$store.commit('setGroups', {
        groups: localStorage.getItem('groups')
      });
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

li.active a,
li.active a:focus {
  color: white;
}

a.pointer {
  cursor: pointer;
}
</style>
